import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Abouts = () => {
  return (
    <React.Fragment>
      <section className="about-section">
        <div className="bg-shape1">
          <div className="container-fluid all-section">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="about-content">
                  <div className="heading">
                    <h2>About Us</h2>
                  </div>
                  <div className="about-txt">
                    <p>
                      Birk Blinds is an Australian company and industry leader,
                      with over 30 years’ experience in bespoke window
                      furnishing design and installation.
                    </p>
                    <p>
                      Our expert Design Consultants will work in partnership
                      with you or your interior designer to bring your vision to
                      life. We ensure every detail of your window furnishings is
                      considered and delivered to the highest standard.
                    </p>
                  </div>
                  <div className="about-txt">
                    <h5>
                      <span className="text-dark">Why Birk? </span>
                    </h5>
                    <div className="why-list ml-4 mb-4">
                      <ul>
                        <li>
                          Over 30 years experience in bespoke Window Furnishings
                        </li>
                        <li>
                          Design consultation with experienced design team
                        </li>
                        <li>Latest automation and technology solutions</li>
                        <li>Custom, Australia made products</li>
                        <li>Globally sourced fabrics</li>
                      </ul>
                    </div>
                  </div>
                  <Link className="btn btn-about" to="/about">
                    Read More &nbsp;
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="about-img">
                  <StaticImage
                    src="../images/babout3.png"
                    alt="Alfesco With Curtains"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="top-bg-shape">
            <StaticImage
              src="../images/about-chair.svg"
              alt="Grey Chair"
              className="img-fluid"
            />
          </div>
          <div className="bottom-bg-shape">
            <StaticImage
              src="../images/Group101.svg"
              alt="Grey Potplant"
              className="img-fluid"
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Abouts;
