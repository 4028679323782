import * as React from "react";
import "./index.css";
import Header from "../components/Header";
import HeaderImage from "../components/HeaderImage";
import Abouts from "../components/Abouts";
import Gallerys from "../components/Gallerys";
import Expertise from "../components/Expertise";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import SEO from "../components/seo";

const IndexPage = () => (
  <React.Fragment>
    <SEO />
    <Helmet>
      <link rel="canonical" href="http://birk.com.au" />
    </Helmet>
    <Header />
    <HeaderImage />
    <Abouts />
    <Gallerys />
    <Expertise />
    <Footer />
  </React.Fragment>
);

export default IndexPage;
