import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Carousel from "react-bootstrap/Carousel";

const HeaderImage = () => {
  return (
    <React.Fragment>
      <div className="slider owl-carousel">
        <Carousel interval={5000} controls={false}>
          <Carousel.Item>
            <div className="slider1a">
              <StaticImage
                src="../images/bslider3.png"
                alt=""
                className="img-fluid"
              />
              <div className="slider-content"></div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="slider1a">
              <StaticImage
                src="../images/bslider6.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="slider1a">
              <StaticImage
                src="../images/bslider7.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="slider1a">
              <StaticImage
                src="../images/bslider1.png"
                alt=""
                className="img-fluid"
              />
              <div className="slider-content"></div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="slider1a">
              <StaticImage
                src="../images/bslider2.png"
                alt=""
                className="img-fluid"
              />
              <div className="slider-content"></div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="slider1a">
              <StaticImage
                src="../images/bslider4.png"
                alt=""
                className="img-fluid"
              />
              <div className="slider-content"></div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="slider1a">
              <StaticImage
                src="../images/bslider5.png"
                alt=""
                className="img-fluid"
              />
              <div className="slider-content"></div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </React.Fragment>
  );
};
export default HeaderImage;
