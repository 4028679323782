import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Expertise = () => {
  return (
    <React.Fragment>
      <section className="expertise-section">
        <div className="bg-shape4">
          <div className="container-fluid all-section">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="expertise-box justify-content-center text-center">
                  <StaticImage
                    src="../images/year200.png"
                    alt="Over 30 Years"
                    className="img-fluid"
                  />
                  <div className="expertise-content">
                    <p>Over 30 Years Experience</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="expertise-box justify-content-center text-center">
                  <StaticImage
                    src="../images/australian_made.png"
                    alt="Australian Made"
                    className="img-fluid"
                  />
                  <div className="expertise-content">
                    <p>Custom Australian Made</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="expertise-box justify-content-center text-center">
                  <StaticImage
                    src="../images/expert.png"
                    alt="Expert Design Consultation"
                    className="img-fluid"
                  />
                  <div className="expertise-content">
                    <p>Expert Design Consultation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="expertise-top-bg-shape">
            <StaticImage
              src="../images/Group131.svg"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Expertise;
