import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Link } from "gatsby";

const Gallerys = () => {
  return (
    <React.Fragment>
      <section className="gallery-section">
        <div className="bg-shape2">
          <div className="container-fluid all-section">
            <div className="row">
              <div className="heading">
                <h2>Products</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <Link to="/roller-blinds">
                  <div className="contains">
                    <StaticImage
                      src="../images/broller.png"
                      alt=""
                      className="img-fluid  d-block"
                    />
                    <div className="overlay">
                      <div className="text">
                        <h4>Roller Blinds</h4>
                        <p>VIEW THIS PRODUCT</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <Link to="/roman-blinds">
                  <div className="contains">
                    <StaticImage
                      src="../images/bromans.png"
                      alt=""
                      className="img-fluid d-block"
                    />
                    <div className="overlay">
                      <div className="text">
                        <h4>Roman Blinds</h4>
                        <p>VIEW THIS PRODUCT</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <Link to="/curtains">
                  <div className="contains">
                    <StaticImage
                      src="../images/bcurtains.png"
                      alt=""
                      className="img-fluid d-block"
                    />
                    <div className="overlay">
                      <div className="text">
                        <h4>Curtains</h4>
                        <p>VIEW THIS PRODUCT</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <Link to="/shutters">
                  <div className="contains">
                    <StaticImage
                      src="../images/bshutters.png"
                      alt=""
                      className="img-fluid d-block"
                    />
                    <div className="overlay">
                      <div className="text">
                        <h4>Shutters</h4>
                        <p>VIEW THIS PRODUCT</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <Link to="/venetians">
                  <div className="contains">
                    <StaticImage
                      src="../images/bvenetians.png"
                      alt=""
                      className="img-fluid d-block"
                    />
                    <div className="overlay">
                      <div className="text">
                        <h4>Venetians</h4>
                        <p>VIEW THIS PRODUCT</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <Link to="/external-range">
                  <div className="contains">
                    <StaticImage
                      src="../images/brange.png"
                      alt=""
                      className="img-fluid d-block"
                    />
                    <div className="overlay">
                      <div className="text">
                        <h4>External Range</h4>
                        <p>VIEW THIS PRODUCT</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <Link to="/automation">
                  <div className="contains">
                    <StaticImage
                      src="../images/gallery/automation/b_automation1.png"
                      alt=""
                      className="img-fluid d-block"
                    />
                    <div className="overlay">
                      <div className="text">
                        <h4>Automation</h4>
                        <p>VIEW THIS PRODUCT</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mt-4">
                <Link to="/honeycomb">
                  <div className="contains">
                    <StaticImage
                      src="../images/bhoneybomb.png"
                      alt=""
                      className="img-fluid d-block"
                    />
                    <div className="overlay">
                      <div className="text">
                        <h4>Honeycomb</h4>
                        <p>VIEW THIS PRODUCT</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="top2-bg-shape">
            <StaticImage
              src="../images/light.svg"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="mid-bg-shape">
            <StaticImage
              src="../images/table.svg"
              alt=""
              className="img-fluid d-block"
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Gallerys;
